/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:0ff880dc-f293-4001-8688-35408b5a1d7d",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_u1TvXnDkj",
    "aws_user_pools_web_client_id": "1vb5gogagl5em54n5ucmk4det4",
    "oauth": {}
};


export default awsmobile;
